<template>
  <el-card class="box-card">
    <el-alert
        title="注意：如果日期范围内已经存在一个发布的活动，则将随机调用"
        center
        show-icon
        type="warning"
        :closable="false">
    </el-alert>
    <el-row style="margin-top: 20px" :gutter="20">
      <el-col :span="4">
        <el-image :src="url" :preview-src-list="url"/>
        <el-form ref="picture" label-width="90px" :model="picture" :rules="rules" style="margin-top: 20px">
          <el-form-item style="margin-bottom: 20px" label="底图" prop="">
            <el-input v-model="picture.name" size="small">
              <template slot="append">尺寸</template>
            </el-input>
          </el-form-item>
          <el-form-item style="margin-bottom: 20px" label="优惠券底图" prop="">
            <el-input v-model="picture.name" size="small">
              <template slot="append">尺寸</template>
            </el-input>
          </el-form-item>
          <el-form-item style="margin-bottom: 20px" label="积分底图" prop="">
            <el-input v-model="picture.name" size="small">
              <template slot="append">尺寸</template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="picture" size="small">保存装修并预览</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="20">
        <el-form ref="form" label-width="90px" :model="form" :rules="rules">
          <el-form-item style="margin-bottom: 20px" label="活动名称" prop="">
            <el-input v-model="form.name" style="width: 25%" size="small"/>
          </el-form-item>
          <el-form-item style="margin-bottom: 20px" label="活动时间:" prop="">
            <el-date-picker
                v-model="query_time"
                size="small"
                :default-time="['00:00:00', '23:59:59']"
                end-placeholder="结束日期"
                format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                type="daterange"
                value-format="yyyy-MM-dd HH:mm:ss"
                @change="getTimeSection"
            />
          </el-form-item>
          <el-form-item label="使用平台" prop="">
            <el-radio-group v-model="form.name" size="small">
              <el-radio-button
                  v-for="role in platform"
                  :key="role.id"
                  :label="role.id"
              >
                {{ role.name }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="奖励内容" prop="">
            <el-radio-group v-model="form.reward_type" size="small">
              <el-radio-button
                  v-for="role in reward_content"
                  :key="role.id"
                  :label="role.id"
              >
                {{ role.name }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-show="form.reward_type == 1" style="margin-bottom: 20px" label="优惠券" prop="">
            <el-button size="small" type="primary">添加优惠券（最多三张）</el-button>
            <el-table v-loading="list_state" size="small" border :data="list" style="width: 500px">
              <el-table-column
                  align="center"
                  prop="name"
                  label="优惠券名">
              </el-table-column>
              <el-table-column
                  width="100"
                  align="center"
                  prop="state"
                  label="现有余量">
              </el-table-column>
              <el-table-column align="center" label="操作" width="60">
                <template #default="{ row }">
                  <el-button type="text" @click="handleDelete(row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item v-show="form.reward_type == 2" style="margin-bottom: 20px" label="积分" prop="">
            <el-input v-model="form.name" style="width: 25%" size="small"/>
          </el-form-item>
          <el-form-item v-show="form.reward_type == 3" style="margin-bottom: 20px" label="余额" prop="">
            <el-input v-model="form.name" style="width: 25%" size="small"/>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submit" size="small">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-card>
</template>
<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      list_state: false,
      list: [{id: 1}, {id: 2}],
      url: ['https://img2.baidu.com/it/u=4050134771,829066775&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=1094'],
      query_time: [],
      rules: {},
      platform: [
        {
          name: '网站',
          id: '1',
        }, {
          name: '小程序',
          id: '2',
        }, {
          name: 'App',
          id: '3',
        },
      ],
      reward_content: [
        {
          name: '优惠券',
          id: 1,
        }, {
          name: '积分',
          id: 2,
        }, {
          name: '账户余额',
          id: 3,
        },
      ],
      picture: {
        name: '',
      },
      form: {
        name: '',
        reward_type: 1,
      },
    }
  },
  created() {
    this.requestObtain()
  },
  methods: {
    //获取时间段
    getTimeSection(event) {
      console.log(event)
      if (event != null) {
        this.form.start_date = event[0]
        this.form.end_date = event[1]
      } else {
        this.form.start_date = ''
        this.form.end_date = ''
      }
    },
    async requestObtain() {
      const {data} = await getExam()
      this.form.num = data.num
      this.form.content = data.content
    },
    async picture() {
      const {code, message} = await Cac(this.picture)
      if (code == '200') {
        this.$message.success(message)
        this.$emit('fetch-data')
        this.close()
      } else {
        this.$message.error(message)
        this.$emit('fetch-data')
        this.close()
      }
    },
    async submit() {
      const {code, message} = await Cac(this.form)
      if (code == '200') {
        this.$message.success(message)
        this.$emit('fetch-data')
        this.close()
      } else {
        this.$message.error(message)
        this.$emit('fetch-data')
        this.close()
      }
    },
  }
}

</script>
<style>
.el-pagination {
  text-align: center;
  margin-top: 20px;
}

.el-form-item {
  margin-bottom: 0;
}

.el-dropdown {
  margin-right: 10px;
}

.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
}

.el-row {
  margin-bottom: 15px;
}
</style>
